import Route from "@ember/routing/route";
import { inject as service } from "@ember/service";
import EntitiesCountriesRouteMixin from "b5b/mixins/entities/countries/route";
import { generateMeta } from "b5b/utils";

export default Route.extend(EntitiesCountriesRouteMixin, {
  store: service(),
  cache: service(),

  beforeModel() {
    return this._super(...arguments);
  },

  // model() {
  //   const continentModel = this.modelFor("continent");
  //   const continentId = continentModel.get("id");
  //   const continentName = continentModel.get("name");

  //   return this.store.query("country", {
  //     filters: JSON.stringify([{
  //       id: continentId,
  //       name: continentName,
  //       type: "continent"
  //     }])
  //   }).then(countries => {
  //     console.log(`Found ${countries.length} countries for continent ${continentName}`);
  //     return countries;
  //   });
  // },

  setupController(controller, model) {
    this._super(...arguments);
    const continentModel = this.modelFor("continent");
    controller.set("countries", model);
    controller.set("continent", continentModel);
    controller.set("selectedContinentNames", [continentModel.get("name")]);

    if (typeof controller.setCountries === 'function') {
      controller.setCountries(model);
    }
  },

  headTags() {
    const continentModel = this.modelFor("continent");
    const title = this.whitelabel.isForTimbuktu
      ? `${continentModel.get("name")} Travel Destinations | ${this.whitelabel.agencySeoName}`
      : `${continentModel.get("name")} | ${this.whitelabel.agencySeoName}`;

    const description = `Explore ${continentModel.get("name")} with ${this.whitelabel.agencySeoName}. Discover the best holiday and vacation destinations in ${continentModel.get("name")}.`;

    if (!this.isFastBoot) {
      document.title = title;
    }

    let robots = this.whitelabel.isForTimbuktu && !this.config.buster
      ? "index, follow"
      : "noindex, nofollow";

    return generateMeta(
      {
        description,
        "og:title": title,
        "og:image": continentModel.get("kodakOriginalUrl"),
        link: `continent/${continentModel.get("continentFriendlyId")}/countries`,
        robots,
      },
      this.whitelabel
    );
  },
});

