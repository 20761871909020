module.exports = {
  white: "#FFFFFF",
  black: "#000000",
  // primary: {
  //   base: "var(--theme)",
  // },
  primary: {
    50: "#fffee6",
    100: "#fffdcd",
    200: "#fffcb3",
    300: "#fffb99",
    400: "#fffa80",
    base: "#fff966",
  },
  secondary: {
    50: "#faf8f6",
    base: "#F0EDE6",
  },
  warning: {
    light: "#FDE047",
    base: "#FACC15",
    dark: "#EAB308",
  },
  error: {
    light: "#FF7171",
    base: "#FF4747",
    dark: "#DD3333",
  },
  success: {
    light: "#4ADE80",
    base: "#22C55E",
    dark: "#16A34A",
  },
  key: {
    excellent: "#C2EBBB",
    good: "#E3F4B0",
    mixed: "#A860CA1A",
  },

  grayscale: {
    50: "#F8FAFC",
    100: "#F1F5F9",
    200: "#EEF2F6",
    300: "#CBD5E1",
    400: "#94A3B8",
    500: "#64748B",
    600: "#475569",
    700: "#2A3646",
    800: "#1B2537",
    900: "#0F172A",
    light: "#1b1b1d",
    base: "#7E7E7E",
  },
  other: {
    amber: "#FCD34D",
    sky: "#38BDF8",
    teal: "#2DD4BF",
    blue: "#3B82F6",
    white: "#FFFFFF",
    camaron: "#FF8092",
    portage: "#887EF9",
  },
};
