import { reads } from "@ember/object/computed";
import { inject as service } from "@ember/service";
import Route from "@ember/routing/route";
import { htmlSafe } from "@ember/string";
import FriendlyIdRoute from "b5b/mixins/friendly-id-route";
import { generateMeta } from "b5b/utils";

export default Route.extend(FriendlyIdRoute, {
  scroll: service(),
  ui: service(),
  session: service(),
  settings: service(),
  headData: service(),
  headTagsService: service("head-tags"),
  cache: service(),

  fastboot: service(),
  isFastBoot: reads("fastboot.isFastBoot"),

  beforeModel(transition) {
    this._super(...arguments);
  },

  // model(params) {
  //   return this.store.findRecord("continent", params.continentFriendlyId);
  // },

  afterModel(model, transition) {
    var breadcrumbList, organization;

    breadcrumbList = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": "http://www.timbuktutravel.com/",
            name: "Home",
            image:
              "https://assets.timbuktutravel.com/lodges/kodak_images/000/000/384/original/family_honeymoon_tent_elephant_pepper_camp_masai_mara_kenya_timbuktu.jpg",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id":
              "https://www.timbuktutravel.com/continent/" +
              model.get("continentFriendlyId"),
            name: model.get("name"),
            image: model.get("kodakOriginalUrl")
              ? model.get("kodakOriginalUrl") + "?w=1200&h=630"
              : "",
          },
        },
      ],
    };

    organization = this.get("settings.organizationSchema");

    this.set("headData.dataStructures", [
      htmlSafe(JSON.stringify(breadcrumbList)),
      htmlSafe(JSON.stringify(organization)),
    ]);

    if (transition.targetName === 'continent.index') {
      return this.store
        .query("country", {
          filters: JSON.stringify([{ type: "continent", id: model.id }]),
        })
        .then((countries) => {
          this.controllerFor("continent").set("countries", countries);
          this.controllerFor("continent.index").set("countries", countries);
        });
    }
  },

  headTags() {
    let model = this.currentModel;
    let title = `${model.get("name")} | ${this.whitelabel.agencySeoName}`;

    let description = model.get("metaDescription")
      ? model.get("metaDescription")
      : `Explore ${model.get("name")} with ${this.whitelabel.agencySeoName}`;

    let robots =
      model.get("published") &&
      this.whitelabel.isForTimbuktu &&
      !this.config.buster
        ? "index, follow"
        : "noindex, nofollow";

    if (!this.isFastBoot) {
      document.title = title;
    }

    let meta = {
      description,
      robots,
      "og:title": title,
      "og:image": model.get("kodakOriginalUrl"),
    };
    if (model.get("published")) {
      meta["link"] = `continent/${model.get("continentFriendlyId")}`;
    }

    return generateMeta(meta);
  },

  setupController: function (controller, model) {
    this._super(...arguments);
    controller.set("continent", model);

    let links = [
      {
        name: "Overview",
        params: {
          routeName: "continent.index",
          routeModel: model,
        },
      },
    ];

    links.push({
      name: "Countries",
      params: {
        routeName: "continent.countries",
        routeModel: model,
      },
    });

    // links.push({
    //   name: "Countries",
    //   params: {
    //     routeName: "countries",
    //     queryParams: { selectedContinentNames: [model.get("name")] },
    //   },
    // });

    if (this.whitelabel.showRoutes) {
      links.pushObject({
        name: "Trips",
        params: {
          routeName: "continent.routes",
          routeModel: model,
        },
      });
    }

    links.push(
      {
        name: "Properties",
        params: {
          routeName: "continent.lodges",
          routeModel: model,
        },
      }
    );

    this.get("session.currentUserPromise").then((user) => {
      if (user && user.get("isTimbuktu")) {
        links.pushObject({
          name: "Tools",
          params: {
            action: "ui.viewedEntityToolsModal",
            actionProperty: model,
          },
        });
      }

      this.get("ui").setProperties({
        entityNavData: {
          entity: model,
          title: model.get("name"),
          titleLink: {
            routeName: "continent",
            routeModel: model,
          },
          links: links,
        },
      });
    });
  },

  resetController(controller, isExiting) {
    this._super(...arguments);
    if (isExiting) {
      this.set("ui.entityNavData", null);
    }
  },
});
