import Component from '@ember/component';

export default Component.extend({
  classNames: ['hero-section'],

  imageUrl: null,
  title: null,
  subtitle: null,
  description: null,
  buttonText: null
});
