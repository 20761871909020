import MapListing from "b5b/components/map-listing/component";
import { maxTripPriceForRange, minTripPriceForRange } from "b5b/utils";

import { inject as service } from "@ember/service";
import { task } from "ember-concurrency";
import { run } from "@ember/runloop";
import { computed } from "ember-decorators/object";
import { or } from "ember-decorators/object/computed";

export default MapListing.extend({
  tagName: "",

  mapOnlyViewTitle: "Trips",

  // referenced in parent component
  minPriceForRange: minTripPriceForRange,
  maxPriceForRange: maxTripPriceForRange,

  ui: service(),
  scroll: service(),
  screen: service(),
  messageBus: service(),
  fastboot: service(),
  whitelabel: service(),
  router: service(),

  xplorer: service("components/x-plorer"),

  reloadTimer: null,

  init() {
    this._super(...arguments);

    this.get("messageBus").subscribe("pill-added", this, this.reload);
    this.get("messageBus").subscribe("pill-removed", this, this.reload);
    this.get("messageBus").subscribe("suggestions-reset", this, this.reload);
    this.get("messageBus").subscribe("suggestions-refresh", this, this.reload);

    this.get("routeType");
  },

  reload() {
    this.get("loadTripsTask").perform();
  },

  @or("xplorer.isLoading", "loadTripsTask.isRunning") routesLoading: false,

  @computed("entityModel")
  bannerImageEntity(entityModel) {
    if (entityModel && entityModel.get("region.id")) {
      return entityModel.get("region");
    } else {
      return entityModel;
    }
  },

  @computed("router.currentURL")
  routeType() {
    const currentURL = this.get("router.currentURL");

    if (
      currentURL &&
      (currentURL.includes("/country/") ||
        currentURL.includes("/region/") ||
        currentURL.includes("/continent/") ||
        currentURL.includes("/routes?filters="))
    ) {
      return "specific";
    }

    return "general";
  },

  actions: {
    openFilterModal() {
      this.set("isFilterModalOpen", true);
    },

    closeFilterModal() {
      this.set("isFilterModalOpen", false);
    },
  },
});
