import { helper } from '@ember/component/helper';
import {
  subtractSecondCurrencyHashContentsFromFirstHash,
  duplicateCurrencyHash
} from 'b5b/helpers/format-currency';

export function subtractPriceHash(params, namedArgs) {
  let fromPriceHashDupe = duplicateCurrencyHash(namedArgs.from);
  let result = subtractSecondCurrencyHashContentsFromFirstHash(fromPriceHashDupe, namedArgs.val);

  // Object.keys(result).forEach((currencyKey) => {
  //   result[currencyKey] = Math.abs(result[currencyKey]);
  // });

  return result;
}

export default helper(subtractPriceHash);
