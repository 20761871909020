import {
  reads
} from '@ember/object/computed';
import {
  inject as service
} from '@ember/service';
import Route from '@ember/routing/route';
import {
  trackEvent
} from 'b5b/utils';

export default Route.extend({
  store: service(),
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),

  model() {
    return this.modelFor('continent');
  },

  setupController(controller, model) {
    this._super(...arguments);
    trackEvent('continent:view');
    controller.set('continent', model);
    let countries = this.controllerFor('continent').get('countries');
    if (countries) {
      controller.set('countries', countries);
    }
  }
});
