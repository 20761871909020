import { inject as service } from "@ember/service";
import Component from "@ember/component";
import { alias, or } from "ember-decorators/object/computed";
import { task } from "ember-concurrency";
import { getLodgeRelatedExperiencesPromise } from "b5b/utils";

export default Component.extend({
  classNames: ["tw-relative"],

  classNameBindings: ["isSelected"],

  tripService: service("trip"),
  router: service(),
  entityModals: service(),
  screen: service(),

  @alias("entityModals.onModal") onModal: null,
  @or("onModal", "openInModal", "changingLodge") modalOpener: null,

  lodge: null,

  isSelected: false,
  isUnexplored: false,
  mapHover: false,
  showPrices: true,
  canSwipe: true, // gallery
  lightboxEnabled: false, // gallery

  init() {
    this._super(...arguments);
    this.set(
      "performingSetupTask",
      this.get("setupLodgeRelatedExperiences").perform()
    );
  },

  didInsertElement() {
    this._super(...arguments);
    this.styleLodgePrices();
  },

  styleLodgePrices() {
    if (!this.element) return;

    const priceContainers = this.element.querySelectorAll(
      ".lodge-price-container"
    );

    priceContainers.forEach((container) => {
      const text = container.textContent || "";
      if (text.includes("person/night")) {
        const originalHtml = container.innerHTML;

        const newHtml = originalHtml.replace(
          /person\/night/g,
          '<span class="tw-p tw-font-[300] tw-text-gray-500">person/night</span>'
        );

        container.innerHTML = newHtml;
      }
    });
  },

  setupLodgeRelatedExperiences: task(function* () {
    let experiences = yield getLodgeRelatedExperiencesPromise(
      this.get("store")
    );
    this.set("lodgeTypeAndActivityExperiences", experiences);
    this.set("lodgeActivityExperiences", this.get("lodge.activityExperiences"));
  }),

  click() {
    if (this.get("lodge") && this.get("mapHover")) {
      this.set("lodge.hover", false);
    }
  },

  mouseEnter() {
    if (this.get("mapHover")) {
      this.set("lodge.hover", true);
    }
    if (this.onMouseEnter) {
      this.onMouseEnter();
    }
  },

  mouseLeave() {
    if (this.get("mapHover")) {
      this.set("lodge.hover", false);
    }
    if (this.onMouseLeave) {
      this.onMouseLeave();
    }
  },

  actions: {
    goToLodge() {
      this.get("router").transitionTo("lodge", this.get("lodge"));
    },

    openModal() {
      if (this.get("changingLodge")) {
        this.get("entityModals").addModalPermission("perm-add-lodge");
      }
      if (this.modalOpener) {
        this.get("entityModals").openModal({
          model: this.get("lodge"),
        });
      }
    },
  },
});
