import DS from "ember-data";
import HasKodakImage from "b5b/mixins/has-kodak-image";

export default DS.Model.extend(HasKodakImage, {
  friendlyId: DS.attr(),
  published: DS.attr(),
  publishedForWaybird: DS.attr(),
  name: DS.attr("string"),
  longitude: DS.attr("string"),
  latitude: DS.attr("string"),
  teaserText: DS.attr("string"),
  kodakOriginalUrl: DS.attr("string"),
  // kodakOriginalUrl: "https://waybird.imgix.net/continents/kodak_images/000/000/001/original/0_-Hero_Image.jpg",
});
