import {
  inject as controller
} from '@ember/controller';
import Mixin from '@ember/object/mixin';
import {
  task
} from 'ember-concurrency';
import {
  trackEvent
} from 'b5b/utils';
import {
  inject as service
} from '@ember/service';
import {
  observer
} from '@ember/object';
import {
  computed
} from 'ember-decorators/object';
import {
  alias
} from 'ember-decorators/object/computed';
import {
  run
} from '@ember/runloop';
import MapSearchingControllerMixin from 'b5b/mixins/map-searching/controller';

export default Mixin.create(MapSearchingControllerMixin, {

  scroll: service(),
  screen: service(),
  messageBus: service(),
  ui: service(),
  xplorer: service('components/x-plorer'),
  applicationController: controller('application'),

  @alias('applicationController.mapOnlyView') mapOnlyView: null,
  @alias('applicationController.filterOnlyView') filterOnlyView: null,

  page: 1,
  per_page: 18,
  sort: 'priority_asc',
  polishLevel: 'default',
  filters: [],
  additionalFilter: null,

  mapSearchEnabled: true,

  onMapPositionChanged() {
    this.loadExperiencesTask.perform();
    this.get('messageBus').publish('listing-map-position-changed');
  },

  @computed('screen.isMobileOnly')
  paginationPageCount(isMobile) {
    return isMobile ? 3 : 10;
  },

  firstTimeLoad: true,

  @alias('xplorer.isLoading') isLoading: null,
  // if you don;t alias the entities like this then pagination wont work
  @alias('xplorer.experiences') experiences: null,
  @alias('experiences') entities: null, // for map searching


  updatePageParam: observer('model.page', function() {
    var page = this.get('model.page');
    if (page) {
      this.set('page', page);
    }

    if (!this.get('firstTimeLoad')) {
      this.get('scroll').to(0, this, 300);
    }

  }),

  queryParams: [{
    selectedContinentNames: {
      as: 'continents'
    },
    selectedCountryNames: {
      as: 'countries'
    },
    selectedRegionNames: {
      as: 'regions'
    },
    selectedExperienceNames: {
      as: 'experiences'
    },
    selectedLodgeNames: {
      as: 'lodges'
    },
    page: {},
    per_page: {},
    polishLevel: {},
    filters: {}
  }],

  selectedCountryNames: [],
  selectedRegionNames: [],
  selectedExperienceNames: [],
  selectedLodgeNames: [],
  isRegional: null,

  popupOpenIndex: null,

  loadExperiencesTask: task(function*() {
    this.loader = run.next(() => {
      // this.get('scroll').to(0);
    });

    if (this.searchMode == 'destinationChange') {
      this.resetLatLng();
    }
    let experiences = yield this.get('xplorer').loadRecords('experiences', {skipSettingsRecords: true});
    this.set('experiences', experiences)
    this.entitiesLoaded();
  }).keepLatest(),

  refreshMapTask: task(function* () {
    yield new Promise((resolve) => run.next(resolve));
    const mapInstance = this.get("mapInstance");
    if (mapInstance) {
      mapInstance.invalidateSize(true);

      yield new Promise((resolve) => run.next(resolve));
      if (this.get("entities.length") && this.get("entityBounds")) {
        this.fitMapToEntityBounds("loading");
      } else if (this.get("zoom") && this.get("center")) {
        mapInstance.setView(this.get("center"), this.get("zoom"));
      } else {
        const defaultCenter = L.latLng(0, 0);
        const defaultZoom = 2;
        mapInstance.setView(defaultCenter, defaultZoom);
      }
    }
  }).keepLatest(),

  scrollToMapTask: task(function*() {
    yield new Promise(resolve => run.next(resolve));

    const mapContainer = document.getElementById("map-container");

    if (mapContainer) {
      const mapOffsetTop = mapContainer.getBoundingClientRect().top + window.pageYOffset;
      const viewportHeight = window.innerHeight;
      const mapHeight = mapContainer.offsetHeight;

      let targetScrollPosition;

      if (mapHeight > viewportHeight * 0.8) {
        targetScrollPosition = mapOffsetTop - 120;
      } else {
        targetScrollPosition = mapOffsetTop - (viewportHeight - mapHeight) / 2;
        if (viewportHeight < 800) {
          targetScrollPosition -= 100;
        } else {
          targetScrollPosition -= 50;
        }
      }

      window.scrollTo({
        top: targetScrollPosition,
        behavior: "smooth",
      });
    } else {
      this.get("scroll").to(0);
    }
  }).drop(),

  actions: {
    hideMarker(marker) {
      if (!marker || !marker.target || this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }
      marker.target.setOpacity(0);
    },

    showMarker(marker) {
      if (!marker || !marker.target || this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }
      marker.target.setOpacity(1);
    },

    openMapPopup(marker) {
      trackEvent('experiences-list-map:open-map-popup');
      this.set('popupOpen', true);

      const searchControl = document.getElementById('search-as-move');
      const zoomControls = document.querySelector('.leaflet-control-zoom');

      if (searchControl) {
        searchControl.style.opacity = '0';
        searchControl.style.pointerEvents = 'none';
      }

      if (zoomControls) {
        zoomControls.style.opacity = '0';
        zoomControls.style.pointerEvents = 'none';
      }

      if (!marker || !marker.target) {
        return;
      }
      marker.target.setOpacity(0);
    },

    closeMapPopup(marker) {
      this.set('popupOpen', false);

      const searchControl = document.getElementById('search-as-move');
      const zoomControls = document.querySelector('.leaflet-control-zoom');

      if (searchControl) {
        searchControl.style.opacity = '1';
        searchControl.style.pointerEvents = 'auto';
      }

      if (zoomControls) {
        zoomControls.style.opacity = '1';
        zoomControls.style.pointerEvents = 'auto';
      }

      if (!marker || !marker.target) {
        return;
      }
      marker.target.setOpacity(1);
    },

    refreshMap() {
      return this.get("refreshMapTask").perform();
    },

    scrollToMap() {
      return this.get("scrollToMapTask").perform();
    },
  }

});
