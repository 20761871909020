import MapListing from "b5b/components/map-listing/component";

import { inject as service } from "@ember/service";
import { or } from "ember-decorators/object/computed";
import { run } from "@ember/runloop";
import { maxLodgePriceForRange, minLodgePriceForRange } from "b5b/utils";
import { getSortTitle } from "b5b/utils";
import { alias } from "@ember/object/computed";

export default MapListing.extend({
  tagName: "",

  ui: service(),
  entityModals: service(),
  screen: service(),
  scroll: service(),
  tripService: service("trip"),
  historyService: service(),
  messageBus: service(),
  xplorer: service("components/x-plorer"),
  pillsService: service("pills"),

  mapOnlyViewTitle: "Properties",

  // referenced in parent component
  minPriceForRange: minLodgePriceForRange,
  maxPriceForRange: maxLodgePriceForRange,
  showMap: false,
  selectedPills: alias("pillsService.selectedPills"),

  @or("xplorer.isLoading", "loadLodgesTask.isRunning") lodgesLoading: false,

  init() {
    this._super(...arguments);
    this.get("messageBus").subscribe("pill-added", this, this.reload);
    this.get("messageBus").subscribe("pill-removed", this, this.reload);
    this.get("messageBus").subscribe("suggestions-reset", this, this.reload);
    this.get("messageBus").subscribe("suggestions-refresh", this, this.reload);
  },

  reload() {
    this.get("loadLodgesTask").perform();
  },

  didInsertElement() {
    this.setupResizeListener();
    this.setupScrollSync();
    this.setupMapVisibilityObserver();
  },

  willDestroyElement() {
    this._super(...arguments);
    run.cancel(this.loader);
    this.teardownScrollSync();
    this.teardownResizeListener();
    this.teardownMapVisibilityObserver();
  },

  setupMapVisibilityObserver() {
    run.next(() => {
      const mapContainer = document.getElementById("map-container");
      if (mapContainer && !this.mapObserverInitialized) {
        this._mapObserver = new IntersectionObserver(
          (entries) => {
            const [entry] = entries;
            if (entry.isIntersecting && entry.intersectionRatio >= 0.7) {
              if (this.get('scrollToMap') && !this.get('mapScrollTriggeredRecently')) {
                this.get('scrollToMap')();
                this.set('mapScrollTriggeredRecently', true);

                run.later(this, function() {
                  this.set('mapScrollTriggeredRecently', false);
                }, 1000);
              }
            }
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 0.7
          }
        );

        this._mapObserver.observe(mapContainer);
      }
    });
  },

  setupScrollSync() {
    const mapContainer = document.getElementById("map-container");
    const listContainer = document.getElementById("lodges-list-container");

    if (mapContainer && listContainer) {
      this._scrollHandler = (event) => {
        if (mapContainer.matches(":hover")) {
          const atTop = listContainer.scrollTop === 0;
          const atBottom =
            listContainer.scrollTop + listContainer.clientHeight >=
            listContainer.scrollHeight;

          if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
            return;
          }
          listContainer.scrollTop += event.deltaY;
          event.preventDefault();
        }
      };

      mapContainer.addEventListener("wheel", this._scrollHandler, {
        passive: false,
      });
    }
  },

  teardownMapVisibilityObserver() {
    if (this._mapObserver) {
      this._mapObserver.disconnect();
      this._mapObserver = null;
    }
  },

  teardownScrollSync() {
    const mapContainer = document.getElementById("map-container");
    if (mapContainer && this._scrollHandler) {
      mapContainer.removeEventListener("wheel", this._scrollHandler);
    }
  },

  setupResizeListener() {
    this._resizeHandler = () => {
      if (window.innerWidth >= 1024) {
        this.set("showMap", false);
      }
    };
    window.addEventListener("resize", this._resizeHandler);
  },

  teardownResizeListener() {
    if (this._resizeHandler) {
      window.removeEventListener("resize", this._resizeHandler);
    }
  },

  actions: {
    setSort(sort) {
      this.set("sort", sort);
      this.set("page", 1);
      this.messageBus.publish("suggestions-refresh");
    },

    resetSuggestionsCategory(name) {
      this._super(...arguments);
      // extends action in parent component
      switch (name) {
        case "selectedAutoBudgetNames":
          this.set("selectedAutoBudgetNames", []);
          break;

        case "selectedLodgeStyleNames":
          this.set("selectedLodgeStyleNames", []);
          break;

        case "selectedBudget":
          this.set("selectedBudget", [
            minLodgePriceForRange(),
            maxLodgePriceForRange(),
          ]);
          break;

        case "showOnlyWithOffers":
          this.set("showOnlyWithOffers", false);
          break;

        case "exclusiveUseOnly":
          this.set("exclusiveUseOnly", false);
          break;

        default:
          break;
      }
    },

    openModal(options) {
      if (this.changingLodge) {
        this.entityModals.addModalPermission("perm-add-lodge");
      }
      if (this.changingLodge) {
        this.entityModals.openModal({
          model: options.model,
        });
      }
    },

    toggleView() {
      this.set("showMap", !this.get("showMap"));

      if (this.get("showMap")) {
        run.next(() => {
          if (this.get('refreshMap')) {
            this.get('refreshMap')();
          }
        });
      }
    },

    scrollToMap() {
      run.next(() => {
        if (this.get('scrollToMap')) {
          this.get('scrollToMap')();
        }
      });
    },

    enablePaneView(view) {
      if (this.get("screen").isMobile) {
        this.set("showMap", view === "map");
      }
    },
  },

  sortTitle: Ember.computed("sort", function () {
    return getSortTitle(this.get("sort"));
  }),
});
