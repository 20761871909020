import { isEmpty } from "@ember/utils";
import { inject as controller } from "@ember/controller";
import Mixin from "@ember/object/mixin";
import { task } from "ember-concurrency";
import { run } from "@ember/runloop";

import MapSearchingControllerMixin from "b5b/mixins/map-searching/controller";

import { inject as service } from "@ember/service";
import { observer } from "@ember/object";
import { computed } from "ember-decorators/object";
import { alias } from "ember-decorators/object/computed";
import { trackEvent } from "b5b/utils";

export const DEFAULT_REGIONS_SORT = "sequence_asc";

export default Mixin.create(MapSearchingControllerMixin, {
  scroll: service(),
  screen: service(),
  messageBus: service(),
  ui: service(),
  xplorer: service("components/x-plorer"),
  applicationController: controller("application"),
  tripService: service("trip"),
  @alias("tripService.currentTrip") trip: false,
  @alias("tripService.currentStage") currentStage: false,
  @alias("tripService.currentStageIndex") stageIndex: false,
  @alias("tripService.changingRegion") changingRegion: false,
  @alias("applicationController.mapOnlyView") mapOnlyView: null,
  @alias("applicationController.filterOnlyView") filterOnlyView: null,

  page: 1,
  per_page: 18,
  polishLevel: "default",
  filters: [],
  additionalFilter: null,

  sort: DEFAULT_REGIONS_SORT,

  mapSearchEnabled: true,

  @computed("screen.isMobileOnly")
  paginationPageCount(isMobile) {
    return isMobile ? 3 : 10;
  },

  firstTimeLoad: true,

  @alias("xplorer.isLoading") isLoading: null,
  // if you don;t alias the entities like this then pagination wont work
  @alias("xplorer.regions") regions: null,

  updatePageParam: observer("model.page", function () {
    var page = this.get("model.page");
    if (page) {
      this.set("page", page);
    }

    if (!this.get("firstTimeLoad")) {
      this.get("scroll").to(0, this, 300);
    }
  }),

  queryParams: [
    {
      selectedContinentNames: {
        as: "continents",
      },
      selectedCountryNames: {
        as: "countries",
      },
      selectedRegionNames: {
        as: "regions",
      },
      selectedMonthNames: {
        as: "months",
      },
      selectedExperienceNames: {
        as: "experiences",
      },
      selectedRegionTypeNames: {
        as: "types",
      },
      polishLevel: {},
      page: {},
      per_page: {},
      sort: {},
      changingRegion: {},
      curentRegionIds: {},
      filters: {},
    },
  ],

  selectedContinentNames: [],
  selectedCountryNames: [],
  selectedRegionNames: [],
  selectedMonthNames: [],
  selectedExperienceNames: [],
  selectedRegionTypeNames: [],

  curentRegionIds: [],

  popupOpenIndex: null,

  @computed("trip.itinerary.stages.[]")
  stages(stages) {
    if (!isEmpty(stages)) {
      return stages.rejectBy("stageType", "add-on");
    }
    return [];
  },

  @computed("regions.[]", "currentStage")
  regionsToShowOnMap(regions, currentStage) {
    if (currentStage && regions) {
      regions = regions.toArray().concat(currentStage.get("region"));
    }
    return regions;
  },

  @computed("trip.itinerary.nonTransferStages.@each.entity")
  polyLineLocations(stages) {
    if (!stages) {
      return [];
    }

    var lines = [];
    for (var i = 0; i < stages.length - 1; i += 1) {
      lines.push([
        stages.objectAt(i).get("mapLocation"),
        stages.objectAt(i + 1).get("mapLocation"),
      ]);
    }
    return lines;
  },

  /*
    Map searching below
  */

  @alias("regions") entities: null, // for map searching

  onMapPositionChanged() {
    this.loadRegionsTask.perform();
    this.get("messageBus").publish("listing-map-position-changed");
  },

  loadRegionsTask: task(function*() {
    this.loader = run.next(() => {
      // this.get('scroll').to(0);
    });
    console.log(this.searchMode)
    if (this.searchMode == 'destinationChange') {
      this.resetLatLng();
    }
    let regions = yield this.get('xplorer').loadRecords('regions', {skipSettingsRecords: true});
    this.set('regions', regions)
    this.entitiesLoaded();
  }).keepLatest(),

  refreshMapTask: task(function* () {
    yield new Promise((resolve) => run.next(resolve));
    const mapInstance = this.get("mapInstance");
    if (mapInstance) {
      mapInstance.invalidateSize(true);

      yield new Promise((resolve) => run.next(resolve));
      if (this.get("entities.length") && this.get("entityBounds")) {
        this.fitMapToEntityBounds("loading");
      } else if (this.get("zoom") && this.get("center")) {
        mapInstance.setView(this.get("center"), this.get("zoom"));
      } else {
        const defaultCenter = L.latLng(0, 0);
        const defaultZoom = 2;
        mapInstance.setView(defaultCenter, defaultZoom);
      }
    }
  }).keepLatest(),

  scrollToMapTask: task(function*() {
    yield new Promise(resolve => run.next(resolve));

    const mapContainer = document.getElementById("map-container");

    if (mapContainer) {
      const mapOffsetTop = mapContainer.getBoundingClientRect().top + window.pageYOffset;
      const viewportHeight = window.innerHeight;
      const mapHeight = mapContainer.offsetHeight;

      let targetScrollPosition;

      if (mapHeight > viewportHeight * 0.8) {
        targetScrollPosition = mapOffsetTop - 120;
      } else {
        targetScrollPosition = mapOffsetTop - (viewportHeight - mapHeight) / 2;
        if (viewportHeight < 800) {
          targetScrollPosition -= 100;
        } else {
          targetScrollPosition -= 50;
        }
      }

      window.scrollTo({
        top: targetScrollPosition,
        behavior: "smooth",
      });
    } else {
      this.get("scroll").to(0);
    }
  }).drop(),

  actions: {
    hideMarker(marker) {
      if (
        !marker ||
        !marker.target ||
        this.get("isDestroying") ||
        this.get("isDestroyed")
      ) {
        return;
      }
      marker.target.setOpacity(0);
    },

    showMarker(marker) {
      if (
        !marker ||
        !marker.target ||
        this.get("isDestroying") ||
        this.get("isDestroyed")
      ) {
        return;
      }
      marker.target.setOpacity(1);
    },

    openMapPopup(marker) {
      trackEvent("regions-list-map:open-map-popup");
      this.set("popupOpen", true);
      const searchControl = document.getElementById('search-as-move');
      const zoomControls = document.querySelector('.leaflet-control-zoom');

      if (searchControl) {
        searchControl.style.opacity = '0';
        searchControl.style.pointerEvents = 'none';
      }

      if (zoomControls) {
        zoomControls.style.opacity = '0';
        zoomControls.style.pointerEvents = 'none';
      }

      if (!marker || !marker.target) {
        return;
      }
      marker.target.setOpacity(0);
    },

    closeMapPopup(marker) {
      this.set("popupOpen", false);

      const searchControl = document.getElementById('search-as-move');
      const zoomControls = document.querySelector('.leaflet-control-zoom');

      if (searchControl) {
        searchControl.style.opacity = '1';
        searchControl.style.pointerEvents = 'auto';
      }

      if (zoomControls) {
        zoomControls.style.opacity = '1';
        zoomControls.style.pointerEvents = 'auto';
      }

      if (!marker || !marker.target) {
        return;
      }
      marker.target.setOpacity(1);
    },

    mouseOverStage(region) {
      this.set("mouseOverRegion", region);
    },

    refreshMap() {
      return this.get("refreshMapTask").perform();
    },

    scrollToMap() {
      return this.get("scrollToMapTask").perform();
    },
  },
});
