import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ENV from 'b5b/config/environment';

export default Route.extend({
  router: service(),

  beforeModel(transition) {
    const requestedUrl = this.paramsFor('unknown-url')['unknown-url'];
    console.log('Unknown URL route captured:', requestedUrl);

    if (requestedUrl === 'destinations' && ENV.environment === 'development') {
      this.router.transitionTo('countries');
      return false;
    }
  }
});
